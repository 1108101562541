<template>
  <v-container class="px-0" id="personal-details-container" fluid>
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="yellow-accent-4"
    ></v-progress-linear>

    <v-container class="pa-0 d-flex flex-column flex-md-row" v-if="personInfo">
      <div class="d-flex flex-column align-start justify-start pa-4">
        <img
          v-if="personInfo.profile_path"
          class="rounded-lg"
          height="400"
          :src="renderPoster(personInfo.profile_path)"
          :lazy-src="defaultCardImage"
        />

        <img v-else class="rounded-lg" height="400" :src="defaultCardImage" />

        <h3 class="mt-4">Personal Info</h3>
        <div class="mt-4">
          <p class="font-weight-medium">Known for</p>
          <p class="text-grey">
            {{ personInfo.known_for_department }}
          </p>
        </div>

        <div class="mt-4">
          <p class="font-weight-medium">Gender</p>
          <p class="text-grey">
            {{ formatGender(personInfo.gender) }}
          </p>
        </div>

        <div class="mt-4">
          <p class="font-weight-medium">Birthday</p>
          <p class="text-grey">
            {{ personInfo.birthday }} {{ formatAge(personInfo.birthday) }}
          </p>
        </div>

        <div class="mt-4">
          <p class="font-weight-medium">Place of birth</p>
          <p class="text-grey">
            {{ personInfo.place_of_birth ? personInfo.place_of_birth : "N/A" }}
          </p>
        </div>

        <div class="mt-4" v-if="personInfo.deathday">
          <p class="font-weight-medium">Deathday</p>
          <p class="text-grey">
            {{ personInfo.deathday }}
          </p>
        </div>

        <div class="mt-4">
          <p
            class="font-weight-medium"
            v-if="personInfo.also_known_as.length >= 1"
          >
            Also known as
            <template
              v-for="(value, i) in personInfo.also_known_as"
              :key="value"
            >
              <p class="text-grey">
                {{ addComma(value, i) }}
              </p>
            </template>
          </p>
        </div>
      </div>
      <div class="mt-4 mt-md-0 px-md-4 w-100">
        <div class="pa-4">
          <h1>{{ personInfo.name }}</h1>
          <p class="font-weight-medium mt-8">Biography</p>
          <p class="mt-4 text-grey-lighten-1">
            {{ personInfo.biography ? personInfo.biography : "N/A" }}
          </p>
        </div>

        <!-- Movie Credits -->
        <v-container v-if="personMovieCredits.length > 0 && !isLoading">
          <v-row no-gutters class="mt-10">
            <v-col md="9">
              <p class="text-h4">Movie Credits</p>
            </v-col>
            <v-col md="3" align="end" justify="center">
              <router-link
                :to="{ name: 'persons.movie', params: { id: id } }"
                class="text-decoration-none"
              >
                <v-icon
                  color="#FFD600"
                  icon="mdi-chevron-right"
                  size="x-large"
                ></v-icon>
              </router-link>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="cast in personMovieCredits"
              :key="cast.id"
              cols="12"
              md="6"
            >
              <v-row
                v-ripple
                class="border pointer"
                no-gutters
                @click="getMovieDetails(cast.id)"
              >
                <v-col md="3">
                  <v-img
                    v-if="cast.poster_path"
                    :height="200"
                    :src="renderPoster(cast.poster_path)"
                    :lazy-src="defaultCardImage"
                    cover
                  ></v-img>
                  <v-img
                    v-else
                    :height="200"
                    :src="defaultCardImage"
                    cover
                  ></v-img>
                </v-col>
                <v-col md="9" class="pa-4">
                  <div>
                    <p class="text-h6 mb-2">{{ cast.original_title }}</p>
                    <p class="text-subtitle-2 text-grey-darken-1 mb-4">
                      {{ cast.character }}
                    </p>
                    <p class="text-grey-darken-1 mb-2">
                      <v-icon
                        color="#FFEB3B"
                        icon="mdi-star"
                        size="x-small"
                      ></v-icon>
                      {{ Math.round(cast.vote_average) }}
                    </p>
                    <p class="text-grey-darken-1 mb-2">
                      {{ parseInt(cast.release_date) }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <!-- Tv Credits -->
        <v-container v-if="personTvCredits.length > 0 && !isLoading">
          <v-row no-gutters class="mt-10">
            <v-col md="9">
              <p class="text-h4">Tv Credits</p>
            </v-col>
            <v-col md="3" align="end" justify="center">
              <router-link
                :to="{ name: 'persons.tv', params: { id: id } }"
                class="text-decoration-none"
              >
                <v-icon
                  color="#FFD600"
                  icon="mdi-chevron-right"
                  size="x-large"
                ></v-icon>
              </router-link>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="cast in personTvCredits"
              :key="cast.id"
              cols="12"
              md="6"
            >
              <v-row
                v-ripple
                class="border pointer"
                no-gutters
                @click="getTvDetails(cast.id)"
              >
                <v-col md="3">
                  <v-img
                    v-if="cast.poster_path"
                    :height="200"
                    :src="renderPoster(cast.poster_path)"
                    :lazy-src="defaultCardImage"
                    cover
                  ></v-img>
                  <v-img
                    v-else
                    :height="200"
                    :src="defaultCardImage"
                    cover
                  ></v-img>
                </v-col>
                <v-col md="9" class="pa-4">
                  <div>
                    <p class="text-h6 mb-2">{{ cast.original_name }}</p>
                    <p class="text-subtitle-2 text-grey-darken-1 mb-4">
                      {{ cast.character }}
                    </p>
                    <p class="text-grey-darken-1 mb-2">
                      <v-icon
                        color="#FFEB3B"
                        icon="mdi-star"
                        size="x-small"
                      ></v-icon>
                      {{ Math.round(cast.vote_average) }}
                    </p>
                    <p class="text-grey-darken-1 mb-2">
                      {{ parseInt(cast.first_air_date) }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <!-- Photos -->
        <v-container
          class="mt-10"
          v-if="personImages.length !== 0 && !imagesIsLoading"
        >
          <p class="text-h4 mb-4">Photos</p>

          <lightgallery
            :settings="{
              speed: 500,
              plugins: plugins,
              mobileSettings: mobileSettings,
              closeOnTap: true,
            }"
          >
            <!-- :data-lg-size="`${image.width}-${image.height}`" -->
            <a
              v-for="(image, i) in personImages"
              :key="i"
              :href="renderPoster(image.file_path)"
              :onBeforeOpen="onBeforeOpen"
              :onBeforeClose="onBeforeClose"
            >
              <img
                v-show="i == 0 || openImageGallery == true"
                :src="renderPoster(image.file_path)"
                height="300"
                class="border"
              />
            </a>
          </lightgallery>
        </v-container>
      </div>
    </v-container>
    <br />
  </v-container>
</template>

<script>
import Lightgallery from "lightgallery/vue";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

export default {
  components: {
    Lightgallery,
  },
  inject: [
    "apiKey",
    "renderPoster",
    "getMovieDetails",
    "getTvDetails",
    "transferToCol",
    "defaultProfilePicture",
    "defaultCardImage",
  ],
  props: ["id"],
  data() {
    return {
      personInfo: null,
      personImages: [],
      model: 0,
      personMovieCredits: [],
      personTvCredits: [],
      knownForCarousel: 0,
      imagesIsLoading: false,
      plugins: [lgThumbnail, lgZoom],
      openImageGallery: false,
      mobileSettings: {
        controls: true,
        showCloseIcon: true,
        download: true,
      },
      isLoading: false,
    };
  },
  methods: {
    onBeforeOpen() {
      this.openImageGallery = true;
    },
    onBeforeClose() {
      this.openImageGallery = false;
    },
    formatGender(gender) {
      if (gender == 1) {
        return "Female";
      } else if (gender == 2) {
        return "Male";
      } else if (gender == 3) {
        return "Non-binary";
      } else {
        return "N/A";
      }
    },
    formatAge(birthdate) {
      const today = new Date();
      const birthdateObj = new Date(birthdate);
      let age = today.getFullYear() - birthdateObj.getFullYear();
      const monthDiff = today.getMonth() - birthdateObj.getMonth();
      const dayDiff = today.getDate() - birthdateObj.getDate();

      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
      }

      return `(${age} years old)`;
    },
    addComma(value, i) {
      return this.personInfo.also_known_as.length == i + 1
        ? value
        : value + ",";
    },
    /**
     * Get the primary person details by id.
     */
    getPerson() {
      this.isLoading = true;
      fetch(
        `https://api.themoviedb.org/3/person/${this.id}?api_key=${this.apiKey}&language=en-US`
      )
        .then((response) => response.json())
        .then((result) => {
          this.personInfo = result;
          this.isLoading = false;
        })
        .catch((error) => {});
    },
    /**
     * Get the movie credits for a person.
     */
    getPersonMovieCredits() {
      fetch(
        `https://api.themoviedb.org/3/person/${this.id}/movie_credits?api_key=${this.apiKey}&language=en-US`
      )
        .then((response) => response.json())
        .then((result) => {
          let sortedByPopularity = result.cast.sort(function (a, b) {
            return b.popularity - a.popularity;
          });

          sortedByPopularity.forEach((element, i) => {
            if (i < 4) {
              this.personMovieCredits.push(element);
            }
          });
        })
        .catch((error) => {});
    },
    /**
     * Get the tv credits for a person.
     */
    getPersonTvCredits() {
      fetch(
        `https://api.themoviedb.org/3/person/${this.id}/tv_credits?api_key=${this.apiKey}&language=en-US`
      )
        .then((response) => response.json())
        .then((result) => {
          let sortedByPopularity = result.cast.sort(function (a, b) {
            return b.popularity - a.popularity;
          });

          // console.log(sortedByPopularity);

          sortedByPopularity.forEach((element, i) => {
            if (i < 4) {
              this.personTvCredits.push(element);
            }
          });
        })
        .catch((error) => {});
    },
    /**
     * Get the images for a person.
     */
    getPersonImages() {
      this.imagesIsLoading = true;
      fetch(
        `https://api.themoviedb.org/3/person/${this.id}/images?api_key=${this.apiKey}`
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          result.profiles.forEach((element) => {
            this.personImages.push(element);
          });
          this.imagesIsLoading = false;
        })
        .catch((error) => {});
    },
    /**
     * Get tagged images for a person.
     */
    getPersonTaggedImages() {
      fetch(
        `https://api.themoviedb.org/3/person/6384/tagged_images?api_key=fd920f7d47c80b3bf8615aec1773db04&language=en-US&page=1`
      )
        .then((response) => response.json())
        .then((result) => {})
        .catch((error) => {});
    },
  },
  mounted() {
    this.getPerson();
    this.getPersonMovieCredits();
    this.getPersonImages();
    this.getPersonTvCredits();
  },
  updated() {
    this.getPersonImages();
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

#personal-details-container {
  min-height: 100vh;
}
</style>

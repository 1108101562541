<template>
  <v-main>
    <v-container fluid>
      <v-alert
        color="error"
        icon="$error"
        title="Invalid"
        text="Page not found."
        class="mt-10"
      ></v-alert>
    </v-container>
  </v-main>
</template>
